import {A} from 'hookrouter';
import { SERVICE_BUTTONS } from '../../app/constants/app';
import UseInstallPWA from '../../app/installButton';

export default function Select(props){

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center">
            <UseInstallPWA/>
            <div className='font-bold text-xl'>Administrador Cabo Corrientes</div>
            <div className='w-full flex items-center justify-center'>
                {
                    SERVICE_BUTTONS.map((b, i) => (
                        <A key={i} href={b.url} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                            <div className='text-xs'>{b.subtext}</div>
                            <div className='font-bold'>{b.text}</div>
                        </A>
                    ))
                }
            </div>
        </div>
    )
}