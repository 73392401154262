import { A, navigate } from 'hookrouter';
import { useEffect, useState } from 'react';
import { URLS } from '../../../app/constants/app';
import { useGlobalContext } from '../../../app/GlobalContext';
import ValesHeader from "./components/ValesHeader";
import { useToasts } from 'react-toast-notifications';

export default function NuevoVale(props){
    const { addToast } = useToasts();
    const {persona} = props;
    const [confirmar, setConfirmar] = useState(false);
    const { persons, putVale, getVales } = useGlobalContext();
    const [target, setTarget] = useState(null);
    const [causa, setCausa] = useState('Pago');
    const [transferencia, setTransferencia] = useState(false);
    const [valor,setValor] = useState(null);
    const [cuil, setCuil] = useState('');

    function elegirCausa(d){
        switch(d){
            default:
            case 0:
                setCausa('Pago');
                break;
            case 1:
                setCausa('Adelanto de sueldo');
                break;
            case 2:
                setCausa('Compras');
                break;
            case 3:
                setCausa('Otros pagos');
                break;
        }
    }

    function classNameOption(data){
        return (data ? 'w-full flex items-center justify-center bg-blue-600 text-white shadow p-2' : 'transform transition duration-100 w-full flex items-center justify-center bg-gray-300 text-gray-500 hover:bg-blue-300 cursor-pointer shadow p-2 hover:shadow-xl');
    }

    function confirmarVale(){
        const data = persons.find(p => p.cuil === cuil);
        if (!data){
            addToast('El cuil ingresado no se encuentra registrado.',{
                appearance: 'error',
                autoDismiss: true
            });
            return null;
        }
        if (data.confecciona){
            let hoy = new Date();
            let newVale = {}
            newVale.target = target;
            newVale.source = data;
            newVale.fecha = `${hoy.getDate()}-${hoy.getMonth() + 1}-${hoy.getFullYear()}`;
            newVale.causa = causa;
            newVale.monto = parseInt(valor);
            newVale.transferencia = transferencia;
            newVale.estado = 0;
            newVale.montoEntregado = 0;
            newVale.pagosParciales = [];
            newVale.ordenesPago= [];
            newVale.firma = '';
            putVale(newVale);
            navigate(URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION);
        } else {
            setCuil('');
            setConfirmar(false);
            addToast('El cuil ingresado no tiene permisos para confeccionar.',{
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    useEffect(() => {
        const data = persons.find(p => p.cuil === persona);
        getVales();
        setTarget(data);
    }, []);

    return (
        <>
            <ValesHeader />
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className="w-2/5 flex flex-col items-center justify-center">
                    {
                        !confirmar && target ?
                        <>
                            <div className='font-bold'>para</div>
                            <div className=''>{target.nombre}</div>
                            <div className='mb-4'>Cuil: {persona}</div>
                            <div className='text-xs font-bold'>Causa del vale</div>
                            <div className='w-full flex items-center justify-center mb-2 text-xs'>
                                <div className={classNameOption((causa === 'Pago'))} onClick={() => elegirCausa(0)}>Pago</div>
                                <div className={classNameOption((causa === 'Adelanto de sueldo'))} onClick={() => elegirCausa(1)}>Adelanto de sueldo</div>
                                <div className={classNameOption((causa === 'Compras'))} onClick={() => elegirCausa(2)}>Compras</div>
                                <div className={classNameOption((causa === 'Otros pagos'))} onClick={() => elegirCausa(3)}>Otros pagos</div>
                            </div>
                            <div className='text-xs font-bold'>Tipo de pago</div>
                            <div className='w-full flex items-center justify-center mb-2 text-xs'>
                                <div className={classNameOption((!transferencia))} onClick={()=> setTransferencia(false)}>Efectivo</div>
                                <div className={classNameOption((transferencia))} onClick={()=> setTransferencia(true)}>Transferencia</div>
                            </div>
                            <div className='text-xs font-bold'>Monto</div>
                            <input value={valor} onChange={(e)=>setValor(e.target.value)} type="tel" placeholder='Ej: 5000' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                            <div className='w-full flex items-center justify-center'>
                                <A href={URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>No editar</div>
                                    <div className='font-bold'>Cancelar</div>
                                </A>
                                <div onClick={() => setConfirmar(true)} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>Confirmar datos</div>
                                    <div className='font-bold'>Guardar</div>
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className='font-bold'>Cuil</div>
                            <input value={cuil} onChange={(e)=>setCuil(e.target.value)} type="text" placeholder='Ingresa tu cuil sin -' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                            <div className='w-full flex items-center justify-center'>
                                <div onClick={() => setConfirmar(false)} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>No confirmar</div>
                                    <div className='font-bold'>Volver</div>
                                </div>
                                <div onClick={confirmarVale} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>Confirmar datos</div>
                                    <div className='font-bold'>Enviar vale</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}