import { navigate } from 'hookrouter';
import { useEffect, useState } from 'react';
import { URLS } from '../../app/constants/app';
import { useGlobalContext } from '../../app/GlobalContext';
import InternetHeader from './components/InternetHeader';

export default function ListasInternet(props){
    const {getSaldosByDate, putSaldo} = useGlobalContext();
    const [fecha, setFecha] = useState('');
    const [torres, setTorres] = useState({});
    const [total, setTotal] = useState([]);
    const [select, setSelect] = useState(-1);
    const [lista, setLista] = useState([]);

    async function seleccionarFecha(){
        const text = `${fecha}-01`;
        const arrayDate = text.split('-');
        const d = new Date(arrayDate);
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const saldos = await getSaldosByDate(`${month}-${year}`);
        setSelect(-1);
        setTotal([]);
        setLista(saldos);
    }

    function imprimir(){
        window.print();
    }

    function deleteSaldo(item){
        let newSaldo = item;
        newSaldo._deleted = true;
        putSaldo(newSaldo);
        navigate(URLS.SERVICIOS.INTERNET + URLS.INTERNET.ADMINISTRACION);
    }

    useEffect(() => {
        const d = new Date();
        const month = d.getMonth() + 1;
        const startData= `${d.getFullYear()}-${(month < 10 ? `0${month}` : month)}`;
        setFecha(startData);
    }, []);

    useEffect(() => {
        if (fecha !== ''){
            seleccionarFecha();
        }
    }, [fecha]);

    useEffect(() => {
        if (lista.length > 0){
            let tabla = {};
            let totales = [];
            for (const p of lista){
                if (!p.lista){
                    if (!tabla[`Torre ${p.usuario.torre}`]) {
                        tabla[`Torre ${p.usuario.torre}`] = [];
                        tabla[`Torre ${p.usuario.torre}`].push(p);
                        totales[`Torre ${p.usuario.torre}`] = p.monto;
                    } else {
                        tabla[`Torre ${p.usuario.torre}`].push(p);
                        totales[`Torre ${p.usuario.torre}`] += p.monto;
                    }
                } else {
                    if (!tabla[`Lista ${p.usuario.lista}`]) {
                        tabla[`Lista ${p.usuario.lista}`] = [];
                        tabla[`Lista ${p.usuario.lista}`].push(p);
                        totales[`Lista ${p.usuario.lista}`] = p.monto;
                    } else {
                        tabla[`Lista ${p.usuario.lista}`].push(p);
                        totales[`Lista ${p.usuario.lista}`] += p.monto;
                    }
                }
            }
            setTorres(tabla);
            setTotal(totales);
        } else {
            setTorres(null);
        }
    }, [lista]);

    return (
        <>
            <InternetHeader/>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='font-bold text-xl'>Listas de Internet</div>
                    <div className="w-4/5 print:w-11/12 flex flex-col items-center justify-center">
                    <input value={fecha} onChange={(e)=>setFecha(e.target.value)} type="month" placeholder='Año' className='transition duration-100 w-full p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    {
                        torres && select === -1 ?
                            <div className='w-full flex items-center justify-center'>
                                {
                                    Object.keys(torres).map((t,i) => 
                                    <div key={i} onClick={() => setSelect(t)} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                        {t}
                                    </div>
                                    )
                                }
                            </div>
                        :
                            null
                    }
                    {
                        select !== -1 ?
                        <>
                            <div className='w-full flex items-center justify-between'>
                                <div className='font-bold m-4 text-xl'>{select}</div>
                                <div>Unidades: {torres[select].length}</div>
                                <div>Total: ${parseInt(total[select])}</div>
                            </div>
                            <div className='w-full flex items-center justify-center'>
                                <div onClick={imprimir} className='noPrint cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    Imprimir
                                </div>
                            </div>
                            <div className='w-full flex flex-col items-center justify-start'>
                                <div className='font-bold w-full flex items-center justify-between mb-2'>
                                    <div className='w-full flex items-center justify-start'>Nombre</div>
                                    <div className='w-full flex items-center justify-start'>Unidad Funcional</div>
                                    <div className='w-full flex items-center justify-start'>Torre</div>
                                    <div className='w-full flex items-center justify-start'>Piso</div>
                                    <div className='w-full flex items-center justify-start'>Dpto</div>
                                    <div className='w-full flex items-center justify-start'>Importe</div>
                                    <div className='w-full flex items-center justify-start'>Adicional Com.</div>
                                    <div className='w-full flex items-center justify-start'>Total</div>
                                    <div className='w-full flex items-center justify-start noPrint'>Acciones</div>
                                </div>
                                {
                                    torres[select].map(p=>
                                        <div className='fila text-xs w-full flex items-center justify-between mb-2 border-b border-gray-300'>
                                            <div className='w-full flex items-center justify-start'>{p.usuario.nombre}</div>
                                            <div className='w-full flex items-center justify-start'>{p.usuario.unidad}</div>
                                            <div className='w-full flex items-center justify-start'>{p.usuario.torre}</div>
                                            <div className='w-full flex items-center justify-start'>{p.usuario.piso}</div>
                                            <div className='w-full flex items-center justify-start'>{p.usuario.dpto}</div>
                                            <div className='w-full flex items-center justify-start'>${p.importe || '-'}</div>
                                            <div className='w-full flex items-center justify-start'>${p.adicionalComunicaciones || '-'}</div>
                                            <div className='w-full flex items-center justify-start'>${p.monto}</div>
                                            <div className='w-full flex items-center justify-start noPrint'>
                                                <div onClick={() => deleteSaldo(p)} className='p-2 bg-blue-500 text-white hover:bg-blue-700 cursor-pointer'>X</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                        :
                        null
                    }
                    </div>
            </div>
        </>
    )
}