import { versions } from "../app/constants/app";

export default function Version(_props) {
	return (
		<div className="w-screen min-h-screen flex flex-col items-start justify-start p-8">
			{versions.map(ver => (
				<div className='w-3/4 flex flex-col items-start justify-start'>
					<div className='font-bold text-xl'><i className='ri-file-paper-fill'/>Versión {ver.name}</div>
					{ver.notes.map(note => (
							<div className='w-full flex flex-col items-start justify-start'>
								<div className='font-bold'>{note.title}</div>
								<p>{note.description}</p>
							</div>
					))}
				</div>
			))}
			<div className='h-1 w-full my-10 bg-black'></div>
		</div>
	);
}