import { useEffect, useState } from 'react';
import { URLS } from '../../../app/constants/app';
import InternetHeader from '../components/InternetHeader';
import {A} from 'hookrouter';
import { useGlobalContext } from '../../../app/GlobalContext';

export default function Admin(props){
    const [lista, setLista] = useState([]);
    const {getUsers, users, putUser, putSaldo} = useGlobalContext();
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [elegido, setElegido] = useState({});
    const [importe , setImporte] = useState('');
    const [eleccionLista, setEleccionLista] = useState(0);
    const [comU, setComU] = useState('');

    const options = [1,2,3,4,5,6,7,8];

    async function deleteUser(user){
        const deleteUser = user;
        deleteUser._deleted = true;
        await putUser(deleteUser);
        await getUsers();
    }

    function abrirModal(u){
        setElegido(u);
        if (u.lista){
            setEleccionLista(u.lista);
        }
        setModal(true);
    }

    function cerrarModal(){
        setElegido({});
        setModal(false);
        setImporte('');
        setComU('');
    }

    async function agregarMonto(){
        let d = new Date();
        let newSaldo = {};
        newSaldo._id = `saldo:${d.getMonth() + 1}-${d.getFullYear()}-${elegido.unidad}`;
        newSaldo.usuario = elegido;
        newSaldo.anoActual = d.getFullYear();
        newSaldo.mesActual = d.getMonth() + 1;
        newSaldo.importe = parseInt(importe);
        newSaldo.adicionalComunicaciones = parseInt(comU);
        newSaldo.monto = parseInt(importe);
        if (eleccionLista && !eleccionLista!==0){
            newSaldo.lista = eleccionLista;
        }
        if (newSaldo.adicionalComunicaciones){
            newSaldo.monto = newSaldo.monto + newSaldo.adicionalComunicaciones;
        }
        putSaldo(newSaldo);
        if (eleccionLista && !eleccionLista!==0){
            let newElegido = elegido;
            newElegido.lista = eleccionLista;
            putUser(newElegido);
        }
        cerrarModal();
    }

    useState(async ()=>{
        await getUsers();
    }, []);

    useEffect(()=>{
        let newList = [];
        if (search.length > 0 && users.length > 0) {
            newList = users.filter(u => {
                const telefono = u.telefono ? u.telefono.toString() : null;
                const unidad = u.unidad ? u.unidad : null;
                const nombre = u.nombre.toLowerCase();
                return (nombre.includes(search.toLowerCase()) || (telefono && telefono === search) || (unidad && unidad === search));
            }
            );
        }
        setLista(newList);
    }, [users, search]);

    return (
        <>
            <InternetHeader/>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='font-bold text-xl'>Administración de Internet</div>
                    <div className="w-2/5 flex flex-col items-center justify-center">
                        <div className='w-full'>
                            <input value={search} onChange={(e)=> setSearch(e.target.value)} type="text" placeholder='Buscar persona...' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        </div>
                        <div className='w-full flex items-center justify-between'>
                            <A href={URLS.SERVICIOS.INTERNET + URLS.INTERNET.LISTAS} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                                Ver Listas
                            </A>
                            <A href={URLS.SERVICIOS.INTERNET + URLS.INTERNET.PROPIETARIOS} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                                Propietarios
                            </A>
                            <A href={URLS.SERVICIOS.INTERNET + URLS.INTERNET.NUEVA_PERSONA + '/nuevo'} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                                Agregar propietario
                            </A>
                        </div>
                        <div className='w-full'>
                            {
                                lista.map((u,i)=>
                                    <div className='w-full p-2 border border-blue-400' key={i}>
                                        <div className='w-full flex items-start justify-center'>
                                            <div className='w-full flex flex-col items-start justify-center'>
                                                <div className='text-xs font-bold'>Nombre</div>
                                                {u.nombre}
                                            </div>
                                            <A href={URLS.SERVICIOS.INTERNET + URLS.INTERNET.NUEVA_PERSONA + '/' + u.unidad} className='font-bold text-blue-400 cursor-pointer'>
                                                Editar
                                            </A>
                                        </div>
                                        <div className='w-full flex items-start justify-center'>
                                            <div className='w-1/2 flex flex-col items-start justify-center'>
                                                <div className='text-xs font-bold'>Email</div>
                                                {u.email}
                                            </div>
                                            <div className='w-1/2 flex flex-col items-start justify-center'>
                                                <div className='text-xs font-bold'>Unidad</div>
                                                {u.unidad}
                                            </div>
                                        </div>
                                        <div className='w-full flex items-center justify-between'>
                                            <div className='w-1/3 flex flex-col items-start justify-center'>
                                                <div className='text-xs font-bold'>Torre</div>
                                                {u.torre}
                                            </div>
                                            <div className='w-1/3 flex flex-col items-start justify-center'>
                                                <div className='text-xs font-bold'>Piso</div>
                                                {u.piso}
                                            </div>
                                            <div className='w-1/3 flex flex-col items-start justify-center'>
                                                <div className='text-xs font-bold'>Dpto</div>
                                                {u.dpto}
                                            </div>
                                        </div>
                                        {
                                            u.alta ?
                                            <div className='w-full flex items-center justify-between'>
                                                <div className='w-1/3 flex flex-col items-start justify-center'>
                                                    <div className='text-xs font-bold'>Internet</div>
                                                    {u.internet}
                                                </div>
                                                <div className='w-1/3 flex flex-col items-start justify-center'>
                                                    <div className='text-xs font-bold'>Telefono</div>
                                                    {u.telefono}
                                                </div>
                                                <div className='w-1/3 flex flex-col items-start justify-center'>
                                                    <div className='text-xs font-bold'>Equipo</div>
                                                    {u.equipo}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className='w-full flex items-center justify-between'>
                                            <div onClick={() => deleteUser(u)} className='font-bold text-red-400 cursor-pointer'>
                                                Eliminar
                                            </div>
                                            <div onClick={() => abrirModal(u)} className='font-bold text-blue-400 cursor-pointer'>
                                                Ingresar monto
                                            </div>
                                            <div className={`font-bold ${(!u.alta ? 'text-green-400' : 'text-green-gray-400')} cursor-pointer`}>
                                                {(u.alta ? 'Dar de baja' : 'Dar de alta')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
            </div>
            {
                modal ?
                <div className='absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-30 flex items-center justify-center'>
                    <div className='w-2/5 p-4 bg-white rounded shadow flex flex-col items-center justify-center'>
                        <div className='w-full flex items-center justify-center font-bold'>
                            Agregar saldo
                        </div>
                        <div className='text-xs font-bold'>Importe</div>
                        <input value={importe} onChange={(e)=> setImporte(e.target.value)} type="tel" placeholder='Importe...' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        <div className='text-xs font-bold'>Cargos comunicación urbana</div>
                        <input value={comU} onChange={(e)=> setComU(e.target.value)} type="tel" placeholder='Cargo de comunicación...' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        <div className='text-xs font-bold'>Lista que pertenece</div>
                        <select value={eleccionLista} onChange={(e)=> setEleccionLista(parseInt(e.target.value))} className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' >
                            <option value={0}>Elegir lista</option>
                            {
                                options.map((o,i)=>
                                <option key={i} value={o}>
                                    Lista {o}
                                </option>
                                )
                            }
                        </select>
                        <div className='w-full flex items-center justify-between'>
                            <div onClick={cerrarModal} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                <div className='text-xs'>volver</div>
                                <div className='font-bold'>Cancelar</div>
                            </div>
                            <div onClick={agregarMonto} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                <div className='text-xs'>Agregar saldo</div>
                                <div className='font-bold'>Finalizar</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            
        </>
    )
}