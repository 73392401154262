import { useEffect, useState } from "react";
import { A, navigate } from 'hookrouter';
import { URLS } from "../../../app/constants/app";
import { useGlobalContext } from "../../../app/GlobalContext";
import ValesHeader from "./components/ValesHeader";

export default function NuevaPersona(props){
    const { persons, putPerson } = useGlobalContext();
    const { id } = props;
    const [person, setPerson] = useState({});
    //form
    const [nombre, setNombre] = useState('');
    const [documento, setDocumento] = useState('');
    const [cuil, setCuil] = useState('');
    const [codigo, setCodigo] = useState('');
    const [email, setEmail] = useState('');
    const [tipo, setTipo] = useState('Empleado');
    const [confecciona, setConfecciona] = useState(false);
    const [valida, setValida] = useState(false);

    function permisos(d){
        switch(d){
            default:
            case 0:
                setConfecciona(false);
                setValida(false);
                break;
            case 1:
                setConfecciona(true);
                setValida(false);
                break;
            case 2:
                setConfecciona(false);
                setValida(true);
                break;
            case 3:
                setConfecciona(true);
                setValida(true);
                break;
        }
    }

    function getClassActive(val){
        return (val ? 'w-full flex items-center justify-center bg-blue-600 text-white shadow p-2' : 'transform transition duration-100 w-full flex items-center justify-center bg-gray-300 text-gray-500 hover:bg-blue-300 cursor-pointer shadow p-2 hover:shadow-xl');
    }

    function guardarPersona(){
        let newPerson = {};
        if (person._id){
            newPerson = person;
        } else {
            newPerson._id = `persona:${nombre}-${codigo}`;
        }
        newPerson.nombre = nombre;
        newPerson.documento = documento;
        newPerson.cuil = cuil;
        newPerson.codigo = codigo;
        newPerson.tipo = tipo;
        if (tipo !== 'Empleado'){
            newPerson.confecciona = false;
            newPerson.valida = false;
        } else {
            newPerson.confecciona = confecciona;
            newPerson.valida = valida;
        }
        putPerson(newPerson);
        navigate(URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION);
        
    }
    useEffect(() => {
        const data = persons.find(p => p.cuil === id);
        if (data && id){
            setPerson(data);
            setNombre(data.nombre);
            setDocumento(data.documento);
            setCodigo(data.codigo);
            setCuil(data.cuil);
            setEmail(data.email);
            setTipo(data.tipo);
            setConfecciona(data.confecciona);
            setValida(data.valida);
        } else {
            setPerson({
                nombre: '',
                documento: '',
                cuil: '',
                codigo: '',
                email: '',
                tipo: 'Empleado'
            });
        }
    }, [persons, id])

    return (
        <>
        <ValesHeader />
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className="w-2/5 flex flex-col items-center justify-center">
                    <div className='text-xs font-bold'>Nombre y Apellido</div>
                    <input value={nombre} onChange={(e)=> setNombre(e.target.value)} placeholder='Ej: Juan Perez' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Documento</div>
                    <input value={documento}  onChange={(e) => setDocumento(e.target.value)} placeholder='Ej: 99999999' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Cuil</div>
                    <input  value={cuil} onChange={(e)=> setCuil(e.target.value)} placeholder='Ej: 20999999995' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Codigo</div>
                    <input value={codigo} onChange={(e)=> setCodigo(e.target.value)} placeholder='Ej: 999' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Email</div>
                    <input value={email} onChange={(e)=> setEmail(e.target.value)} placeholder='Ej: cabo@corrientes.com' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Tipo</div>
                    <div className='w-full flex items-center justify-center mb-2 text-xs'>
                        <div className={getClassActive((tipo === 'Empleado'))} onClick={()=> setTipo('Empleado')}>Empleado</div>
                        <div className={getClassActive((tipo === 'Proveedor'))} onClick={()=> setTipo('Proveedor')}>Proveedor</div>
                    </div>
                    {
                        tipo === 'Empleado' ?
                        <>
                            <div className='text-xs font-bold'>Permisos</div>
                            <div className='w-full flex items-center justify-center mb-2 text-xs'>
                                <div className={getClassActive((!valida && !confecciona))} onClick={() => permisos(0)}>Ninguno</div>
                                <div className={getClassActive((!valida && confecciona))} onClick={() => permisos(1)}>Confexiona</div>
                                <div className={getClassActive((valida && !confecciona))} onClick={() => permisos(2)}>Valida</div>
                                <div className={getClassActive((valida && confecciona))} onClick={() => permisos(3)}>Valida y Confexiona</div>
                            </div>
                        </>
                    :
                    null
                    }
                </div>
                <div className='w-full flex items-center justify-center'>
                    <A  href={URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                        <div className='text-xs'>No editar</div>
                        <div className='font-bold'>Cancelar</div>
                    </A>
                    <div onClick={guardarPersona} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                        <div className='text-xs'>Confirmar datos</div>
                        <div className='font-bold'>Guardar</div>
                    </div>
                </div>
            </div>
        </>
    );
}