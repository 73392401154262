import { useState, useEffect, useRef } from "react"
import { useGlobalContext } from "../../../app/GlobalContext";
import ValesHeader from "./components/ValesHeader";

export default function VerVale(props){
    const {id} = props;
    const elem = useRef();
    const {getValeByNumber, estados} = useGlobalContext();
    const [vale, setVale] = useState(null);

    async function elegirVale() {
        // aca se busca el vale por numero, si se encuentra se cambia el estado a 1 y se llea vale con los datos del vale.
        const data = await getValeByNumber(parseInt(id));
        if (data){
            setVale(data);
        }
    }

    function parseEstado(estado){
        const e = estados.find(e => e.val === estado);
        return e.nombre || 'Sin estado';
    }

    function print(){
        window.print();
    
        return true;
    }

    useEffect(() => {
        elegirVale();
    }, []);
    return(
        <>
        <ValesHeader />
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div onClick={print} className='cursor-pointer text-blue-600 font-bold mb-4 noPrint'>
                    Imprimir
                </div>
                <div ref={elem} className='print:w-4/5 w-2/5 flex flex-col items-center justify-start'>
                    {
                        vale ? 
                        <>
                            <div className='w-full flex flex-col items-center justify-start'>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Nombre: </span>{vale.target.nombre}</div>
                                    <div><span className='font-bold'>Documento: </span>{vale.target.documento}</div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Causa: </span>{vale.causa}</div>
                                    <div><span className='font-bold'>Metodo: </span>{vale.transferencia ? 'Transferencia' : 'Efectivo'}</div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>cantidad pagada: </span>${vale.montoEntregado}</div>
                                    <div><span className='font-bold'>Monto final: </span>${vale.monto}</div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Estado: </span>{parseEstado(vale.estado)}</div>
                                </div>
                                {
                                    vale.firma ?
                                    <div className='w-full flex items-center justify-end'>
                                        <img alt="firma" className='bg-gray-100 w-32 h-32' src={vale.firma}/>
                                    </div>
                                :
                                null
                                }
                                <div className='w-full h-px bg-blue-600 rounded opacity-50 my-4'>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div className='font-bold'>Entregado por: </div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Nombre: </span>{vale.source.nombre}</div>
                                    <div><span className='font-bold'>Documento: </span>{vale.source.documento}</div>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                </div>
            </div>
        </>
    )
}