import { navigate } from "hookrouter";
import { useEffect, useRef, useState } from "react";
import { URLS } from "../../app/constants/app";
import { useGlobalContext } from "../../app/GlobalContext";
import ValesHeader from "./Admin/components/ValesHeader";

export default function Entregas(props){
    const { getValeByNumber, putVale } = useGlobalContext();
    const canvasRef = useRef();
    const [number, setNumber] = useState(null);
    const [vale, setVale] = useState(null);
    const [state, setState] = useState(0);

    //canvas
    const [ctx, setCtx] = useState(null);

    //draw
    const [isDrawing, setIsDrawing] = useState(false);

    function cancelar(){
        setVale(null);
        setState(0);
        setNumber(null);
    }

    async function elegirVale() {
        // aca se busca el vale por numero, si se encuentra se cambia el estado a 1 y se llea vale con los datos del vale.
        const data = await getValeByNumber(parseInt(number));
        if (data){
            setVale(data);
            setState(1);
        } else {
            cancelar();
        }
    }

    function firmarVale(){
        setState(2);
    }

    /*
    function parseEstado(estado){
        let data = '';
        switch(estado){
            default:
            case 0:
                data = 'Adelanto de sueldo';
                break;
            case 1:
                data = 'Compras';
                break;
            case 2:
                data = 'Otros pagos';
                break;
        }

        return data;
    }
    */

    useEffect(() => {
        if (state === 2 && !!canvasRef.current){
            setCtx(canvasRef.current.getContext('2d'));
            console.log(canvasRef.current);
        }
    }, [canvasRef, state]);

    function getOffsetLeft( elem ){
    	var offsetLeft = 0;
    	do {
      	if ( !isNaN( elem.offsetLeft ) )
      	{
         	 offsetLeft += elem.offsetLeft;
      	}
    	} while( elem = elem.offsetParent );
    	return offsetLeft;
	}

	function getOffsetTop( elem ){
    	var offsetTop = 0;
    	do {
      	if ( !isNaN( elem.offsetTop ) )
      	{
         	 offsetTop += elem.offsetTop;
      	}
    	} while( elem = elem.offsetParent );
    	return offsetTop;
	}

    function startDrawing(e) {
        console.log(ctx.canvas.offsetTop);
        setIsDrawing(true);
        ctx.beginPath();
        ctx.moveTo(e.pageX - getOffsetLeft(ctx.canvas), e.pageY - getOffsetTop(ctx.canvas));
     }
    
     function draw(e) {
        if (isDrawing === true) {
            const cx = e.touches !== undefined
			? e.touches[0].clientX
			: e.clientX;
            const cy = e.touches !== undefined
			? e.touches[0].clientY
			: e.clientY;
           var x = cx - getOffsetLeft(ctx.canvas);
           var y = cy - getOffsetTop(ctx.canvas);
        
           ctx.lineTo(x, y);
           ctx.stroke();
        }
     }

     function stopDrawing() {
        setIsDrawing(false);
     }

     function cleanCanvas() {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
     }

     function save(){
        const newVale = vale;
        const firma = ctx.canvas.toDataURL();
        newVale.firma = firma;
        putVale(newVale);
        cancelar();
     }

     

    useEffect(() => {
        if (!!ctx && state === 2){
            ctx.fillStyle = 'white';
            ctx.fillRect(0,0,ctx.canvas.width,ctx.canvas.height);
            ctx.canvas.width = 300;
            ctx.canvas.height = 300;
        }
    }, [ctx, state]);

    return (
        <>
            <ValesHeader/>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='w-2/5 flex flex-col items-center justify-start'>
                {
                    state === 0 && !vale ?
                    <>
                        <input value={number} onChange={(e) => setNumber(e.target.value)} type="text" placeholder='Cual es el numero del vale?' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        <div className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110' onClick={elegirVale}>
                            Elegir Vale
                        </div>
                    </>
                    :
                    <div className='font-bold text-xl'>Vale Nro {vale.numero}</div>
                }
                </div>
                {
                    state === 1 ?
                    <div className='w-2/5 flex flex-col items-center justify-start'>
                        <div className='w-full flex items-center justify-between'>
                            <div><span className='font-bold'>Nombre: </span>{vale.target.nombre}</div>
                            <div><span className='font-bold'>Documento: </span>{vale.target.documento}</div>
                        </div>
                        <div className='w-full flex items-center justify-between'>
                            <div><span className='font-bold'>Causa: </span>{vale.causa}</div>
                            <div><span className='font-bold'>Metodo: </span>{vale.transferencia ? 'Transferencia' : 'Efectivo'}</div>
                        </div>
                        <div className='w-full flex items-center justify-between'>
                            <div><span className='font-bold'>Monto: </span>${vale.monto}</div>
                        </div>
                        <div className='w-full h-px bg-blue-600 rounded opacity-50 my-4'>
                        </div>
                        <div className='w-full flex items-center justify-between'>
                            <div className='font-bold'>Entregado por: </div>
                        </div>
                        <div className='w-full flex items-center justify-between'>
                            <div><span className='font-bold'>Nombre: </span>{vale.source.nombre}</div>
                            <div><span className='font-bold'>Documento: </span>{vale.source.documento}</div>
                        </div>
                        <div className='w-full flex items-center justify-center'>
                            <div className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110' onClick={cancelar}>
                                Cancelar
                            </div>
                            {
                                !vale.firma ?
                                <div className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110' onClick={firmarVale}>
                                    Ir a firmar
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    null
                }
                {
                    state === 2 ?
                    <div className='w-2/5 flex flex-col items-center justify-start'>
                        <div className='w-full flex items-center justify-center'>
                            <canvas onTouchStart={startDrawing} onTouchMove={draw} onTouchEnd={stopDrawing} onMouseDown={startDrawing} onMouseUp={stopDrawing} onMouseMove={draw} style={{width:300, height:300, touchAction: 'none'}} className='border border-blue-600 bg-white' ref={canvasRef}></canvas>
                        </div>
                        <div className='w-full flex items-center justify-center'>
                            <div className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110' onClick={cancelar}>
                                Cancelar
                            </div>
                            <div className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110' onClick={cleanCanvas}>
                                Limpiar firma
                            </div>
                            <div className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110' onClick={save}>
                                Confirmar
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </>
    );
}