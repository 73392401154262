import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../app/GlobalContext";
import { A } from 'hookrouter';
import ValesHeader from "./components/ValesHeader";
import { URLS } from "../../../app/constants/app";

export default function Admin(){
    const {persons, getPersons} = useGlobalContext();
    const [search, setSearch] = useState('');
    const [state, setState] = useState(0);
    const [list, setList] = useState([]);


    function renderPerson(data,key){
        return (
            <div key={key} className='w-full flex items-center justify-between h-20  mb-2 border-b border-t shadow border-blue-300'>
                <div className="w-full h-full flex flex-col items-center justify-center border-l border-blue-300 ">
                    <div className='font-bold text-xs'>Nombre</div>
                    <div className='text-xs'>{data.nombre}</div>
                </div>
                <div className="w-full h-full flex flex-col items-center justify-center border-l border-blue-300 ">
                    <div className='font-bold text-xs'>{data.cuil ? 'Cuil' : 'Documento'}</div>
                    <div className='text-xs'>{data.cuil || data.documento}</div>
                </div>
                <div className="w-full h-full border-r border-l border-blue-300  flex flex-col items-center justify-around">
                    <A href={URLS.SERVICIOS.VALES + URLS.VALES.NUEVA_PERSONA + '/' + data.cuil} className='font-bold text-xs text-blue-600 cursor-pointer hover:text-blue-400'>Editar Persona</A>
                    <A href={URLS.SERVICIOS.VALES + URLS.VALES.NUEVO_VALE + `/${data.cuil}`} className='font-bold text-xs text-blue-600 cursor-pointer hover:text-blue-400'>Realizar Vale</A>
                </div>
            </div>
        )
    }

    useEffect(() => {
        getPersons();
    },[]);

    useEffect(() => {
        const newList = persons.filter(p => p.nombre.toUpperCase().includes(search.toUpperCase()));
        setList(newList);
    }, [persons, search])

    return (
        <>
            <ValesHeader />
            <div className='w-full h-full flex flex-col items-center justify-center'>
            <div className='font-bold text-xl'>Administración de Vales</div>
                <div className="w-2/5 flex flex-col items-center justify-center">
                    <div className='w-full'>
                        <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder='Buscar persona...' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    </div>
                    <div className='w-full flex items-center justify-between'>
                        <A href={URLS.SERVICIOS.VALES + URLS.VALES.LISTA_VALES} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                            Ver Vales
                        </A>
                        <A href={URLS.SERVICIOS.VALES + URLS.VALES.NUEVA_PERSONA + '/nuevo'} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                            Agregar persona
                        </A>
                    </div>
                </div>
                {
                    search !== '' && search.length > 2 && state !== 1 ?
                        <div className='w-2/5 flex flex-col items-center justify-start'>
                            {
                                list.length > 0 ?
                                list.map((p,i) => (
                                    renderPerson(p,i)
                                ))
                                :
                                <div>No se encontro ningun resultado...</div>
                            }
                        </div>
                    :
                        null
                }
                {
                    state === 1 ?
                        <div className='w-2/5 flex flex-col items-center justify-start'>
                            <div>Datos de esa persona (acciones)</div>
                        </div>
                    :
                        null
                }
            </div>
        </>
    )
}