import { createContext, useContext, useEffect, useState } from 'react';
import { SERVICE_INTERNET, SERVICE_VALES, SERVICE_INVENTARY, URLS } from './constants/app';
import { useToasts } from 'react-toast-notifications';
import {navigate} from 'hookrouter';

import superagent from 'superagent';

const globalContext = createContext({});

export function GlobalProvider({children}){
    const { addToast } = useToasts();
    const dev = false;
    const ip = (dev ? 'http://200.58.99.151:5984/cabogeneral' : 'https://vps-1858514-x.dattaweb.com/cabogeneral');
    const authCode = (dev ? 'Y2FibzpjYWJv' : 'Y2FibzpjYWJv');

    const [user, setUser] = useState({});
    const [activeService, setActiveService] = useState('login');
    const [persons, setPersons] = useState([]);
    const [users, setUsers] = useState([]);
    const [vales, setVales] = useState([]);
    const [objects, setObjects] = useState([]);

    const estados = [
        {val:0, nombre: 'En espera'},
        {val:1, nombre: 'Validado'},
        {val:2, nombre: 'Activo'},
        {val:3, nombre: 'Pago'},
        {val:4, nombre: 'Anulado'}
    ];

    // chupar datos con fetch
    // hacer funciones que manejen estas funciones y interactueen con el UI
    //
    function preventNoUser(){
        if (!user.documento){
            addToast('Cuenta de administrador no encontrada, por favor vuelve a relogear.',{
                appearance: 'error',
                autoDismiss: true
            });
            navigate('/');
            return;
        }
    }
    /**
     * Selecciona que servicio esta activo en el sistema. // 0 = internet, 1 = vales, 2 = inventario
     * @param {Number} service
     */
    function selectActiveService(service = 0) {
        switch (service) {
            default:
            case 0:
                setActiveService(SERVICE_INTERNET);
                break;
            case 1:
                setActiveService(SERVICE_VALES);
                break;
            case 2:
                setActiveService(SERVICE_INVENTARY);
                break;
        }
    }

    /**
     * Obtiene todos los vales.
     * @param {Number} numero_vale
     */
     async function getVales() {
        preventNoUser();
        try {
            const vales = await superagent.get(`${ip}/_partition/vale/_all_docs?include_docs=true`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`);

            if (vales.ok){
                const list = vales.body.rows.map((v) => v.doc);
                setVales(list);
            }

            return null;
        } catch(err){
            console.log('error', err);
        }
    }

    /**
     * Obtiene un vale segun su numero.
     * @param {Number} numero_vale
     */
    async function getValeByNumber(number) {
        preventNoUser();
        try {
            const vales = await superagent.get(`${ip}/_partition/vale/_all_docs?include_docs=true`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`);

            if (vales.ok){
                const vale = vales.body.rows.find(v => v.doc.numero === number);
                return (vale ? vale.doc : null);
            }

            return null;
        } catch(err){
            console.log('error', err);
        }
    }

    /**
     * Obtiene una persona segun su numero de documento.
     * @param {Number} numero_documento
     */
     async function getPersonByDocument(number) {
        try {
            const vales = await superagent.get(`${ip}/_partition/persona/_all_docs?include_docs=true`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`);

            if (vales.ok){
                const vale = vales.body.rows.find(v => v.doc.documento === number);
                return (vale ? vale.doc : null);
            }

            return null;
        } catch(err){
            console.log('error', err);
        }
    }

    /**
     * Obtiene la lista de personas para los vales.
     */
    async function getPersons(){
        preventNoUser();
        try {
            const personas = await superagent.get(`${ip}/_partition/persona/_all_docs?include_docs=true`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`);
            if (personas.ok){
                const list = personas.body.rows.map(p => p.doc);
                setPersons(list);
            }
        } catch(err){
            console.log('error',err);
        }
    }

    /**
     * Obtiene la lista de usuarios de internet y telefonia. PROPIETARIOS
     */
     async function getUsers(){
        preventNoUser();
        try {
            const personas = await superagent.get(`${ip}/_partition/usuario/_all_docs?include_docs=true`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`);
            if (personas.ok){
                const list = personas.body.rows.map(p => p.doc);
                setUsers(list);
            }
        } catch(err){
            console.log('error',err);
        }
    }
    /**
     * Agrega o actualiza una persona de internet. PROPIETARIOS
     * @param {Object} data
     */
     async function putUser(data){
        preventNoUser();
        try{
            const put = await superagent.put(`${ip}/${data._id}`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`)
            .send(data);
            if (put.ok){
                addToast('Persona actualizada correctamente.',{
                    appearance: 'info',
                    autoDismiss: true
                });
                getPersons();
            }
        } catch(err){
            addToast('Error al actualizar a la persona.',{
                appearance: 'error',
                autoDismiss: true
            });
            console.log('error',err);
        }
    }

    /**
     * Agrega o actualiza una persona de internet.
     * @param {Object} data
     */
     async function putSaldo(data){
        console.log(data);
        preventNoUser();
        try{
            const put = await superagent.put(`${ip}/${data._id}`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`)
            .send(data);
            if (put.ok){
                addToast('Saldo actualizado.',{
                    appearance: 'info',
                    autoDismiss: true
                });
                getPersons();
            }
        } catch(err){
            addToast('Error al actualizar a el saldo.',{
                appearance: 'error',
                autoDismiss: true
            });
            console.log('error',err);
        }
    }

    /**
     * Obtiene la lista de saldos internet en una fecha.
     */
     async function getSaldosByDate(date){
        preventNoUser();
        try {
            const personas = await superagent.get(`${ip}/_partition/saldo/_all_docs?include_docs=true`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`);
            if (personas.ok){
                const list = personas.body.rows.map(p => p.doc);
                return list.filter(p => p._id.includes(date));
            }
        } catch(err){
            console.log('error',err);
        }
    }
    /**
     * Agrega o actualiza un vale.
     * @param {Object} data
     */
    async function putVale(data) {
        preventNoUser();
        try{
            let newData = data;
            if (!newData._id){
                newData.numero = vales.length + 1;
                newData._id = `vale:${newData.fecha}-${newData.target.documento}-${vales.length + 1}`;
            }
            const put = await superagent.put(`${ip}/${data._id}`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`)
            .send(newData);
            if (put.ok){
                addToast('Vale actualizado correctamente.',{
                    appearance: 'info',
                    autoDismiss: true
                });
                getVales();
            }
        } catch(err){
            addToast('Error al actualizar al Vale.',{
                appearance: 'error',
                autoDismiss: true
            });
            console.log('error',err);
        }
    }
    /**
     * Agrega o actualiza una persona.
     * @param {Object} data
     */
    async function putPerson(data){
        preventNoUser();
        try{
            const put = await superagent.put(`${ip}/${data._id}`)
            .set('Accept', 'application/json')
            .set('Authorization', `Basic ${authCode}`)
            .send(data);
            if (put.ok){
                addToast('Persona actualizada correctamente.',{
                    appearance: 'info',
                    autoDismiss: true
                });
                getPersons();
            }
        } catch(err){
            addToast('Error al actualizar a la persona.',{
                appearance: 'error',
                autoDismiss: true
            });
            console.log('error',err);
        }
    }

    useEffect(() => {
        addToast('Aplicacion cargada con exito',{
            appearance: 'info',
            autoDismiss: true
        });
    },[])

    return (
        <globalContext.Provider value={ {users, estados, activeService, user, persons, vales, objects, putSaldo, getUsers, putUser, getSaldosByDate, getPersonByDocument, putVale, putPerson, getVales, selectActiveService, getValeByNumber, getPersons, setVales, setObjects, setUser} }>
            {children}
        </globalContext.Provider>
    )
}

export function useGlobalContext() {
    const connect = useContext(globalContext);
    return connect;
}