import { useEffect, useState } from "react";
import {A, navigate} from 'hookrouter';
import { URLS } from "../../../app/constants/app";
import InternetHeader from "../components/InternetHeader";
import { useGlobalContext } from "../../../app/GlobalContext";

export default function NuevaPersona({id}){
    const [oldUser, setOldUser] = useState({});
    const {users, putUser} = useGlobalContext();
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [unidad, setUnidad] = useState('');
    const [torre, setTorre] = useState('');
    const [piso, setPiso] = useState('');
    const [dpto, setDpto] = useState('');
    const [alta, setAlta] = useState(false);
    const [internet, setInternet] = useState('');
    const [equipo, setEquipo] = useState('');
    const [telefono, setTelefono] = useState('');

    function getClassActive(val){
        return (val ? 'w-full flex items-center justify-center bg-blue-600 text-white shadow p-2' : 'transform transition duration-100 w-full flex items-center justify-center bg-gray-300 text-gray-500 hover:bg-blue-300 cursor-pointer shadow p-2 hover:shadow-xl');
    }

    function guardarPersona(){
        const d = new Date();
        let newPerson = {};
        if (oldUser._id){
            newPerson = oldUser;
        } else {
            newPerson._id = `usuario:${unidad}`;
        }
        newPerson.nombre = nombre.trim();
        newPerson.email = email;
        newPerson.unidad = unidad;
        newPerson.torre = torre;
        newPerson.piso = piso;
        newPerson.dpto = dpto;
        newPerson.alta = alta;
        newPerson.internet = internet;
        newPerson.equipo = equipo;
        newPerson.telefono = telefono;
        newPerson.fecha = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        newPerson.diaAlta = d.getDate();
        newPerson.mesAlta = d.getMonth() + 1;
        newPerson.anoAlta = d.getFullYear();
        putUser(newPerson);
        navigate(URLS.SERVICIOS.INTERNET + URLS.VALES.ADMINISTRACION);
    }

    useEffect(() => {
        console.log(id);
        const data = users.find(u => u._id === `usuario:${id}`);
        console.log(data);
        if (data && id){
            setOldUser(data);
            setNombre(data.nombre);
            setEmail(data.email);
            setUnidad(data.unidad);
            setTorre(data.torre);
            setPiso(data.piso);
            setDpto(data.dpto);
            setAlta(data.alta);
            if (data.alta){
                setInternet(data.internet);
                setEquipo(data.equipo);
                setTelefono(data.telefono);
            }
        } else {
            setOldUser({
                nombre:'',
                email:'',
                unidad:'',
                torre:'',
                piso:'',
                dpto:'',
                alta: false,
                internet:'',
                equipo:'',
                telefono:''
            });
        }
    }, [])

    return (
        <>
            <InternetHeader/>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className="w-2/5 flex flex-col items-center justify-center">
                    <div className='text-xs font-bold'>Nombre</div>
                    <input value={nombre} onChange={(e)=> setNombre(e.target.value)} placeholder='Ej: Mauro Perez' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Email</div>
                    <input value={email} onChange={(e)=> setEmail(e.target.value.trim())} placeholder='Ej: Mauro Perez' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='text-xs font-bold'>Unidad</div>
                    <input value={unidad} onChange={(e)=> setUnidad(e.target.value.trim())} placeholder='Ej: 999' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='w-full flex items-center justify-center'>
                        <div className='w-1/3 flex flex-col items-center justify-center'>
                            <div className='text-xs font-bold'>Torre</div>
                            <input value={torre} onChange={(e)=> setTorre(parseInt(e.target.value))} placeholder='Ej: 1' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        </div>
                        <div className='w-1/3 flex flex-col items-center justify-center'>
                            <div className='text-xs font-bold'>Piso</div>
                            <input value={piso} onChange={(e)=> setPiso(e.target.value.trim())} placeholder='Ej: 2' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        </div>
                        <div className='w-1/3 flex flex-col items-center justify-center'>
                            <div className='text-xs font-bold'>Dpto</div>
                            <input value={dpto} onChange={(e)=> setDpto(e.target.value.toUpperCase().trim())} placeholder='Ej: B' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-center mb-2 text-xs'>
                        <div className={getClassActive((!alta))} onClick={()=> setAlta(false)}>Sin servicios</div>
                        <div className={getClassActive((alta))} onClick={()=> setAlta(true)}>Con servicios</div>
                    </div>
                    {
                        alta ?
                        <>
                            <div className='text-xs font-bold'>Equipo</div>
                            <input value={equipo} onChange={(e)=> setEquipo(e.target.value.trim())} placeholder='Ej: 11231241' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                            <div className='text-xs font-bold'>Internet</div>
                            <input value={internet} onChange={(e)=> setInternet(e.target.value.trim())} placeholder='Ej: 223494431' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                            <div className='text-xs font-bold'>Telefono</div>
                            <input value={telefono} onChange={(e)=> setTelefono(e.target.value.trim())} placeholder='Ej: 11E2312B41A' type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                        </>
                        :
                        null
                    }
                    <div className='w-full flex items-center justify-center'>
                        <A  href={URLS.SERVICIOS.INTERNET + URLS.VALES.ADMINISTRACION} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                            <div className='text-xs'>No editar</div>
                            <div className='font-bold'>Cancelar</div>
                        </A>
                        <div onClick={guardarPersona} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                            <div className='text-xs'>Confirmar datos</div>
                            <div className='font-bold'>Guardar</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}