import{ A } from 'hookrouter';
import { useState } from "react";
import { URLS } from "../../../app/constants/app";
import { useGlobalContext } from "../../../app/GlobalContext";
import InternetHeader from "../components/InternetHeader";

export default function ListaPropietarios(){
    const { users, getUsers } = useGlobalContext();
    const [ lista, setLista ] = useState({});

    function renderList(l){
        return (
            <div className='w-full flex flex-col items-center justify-start'>
                {
                    lista[l].sort((a,b)=> a.unidad - b.unidad).map((u,i) => {
                        return (
                            <div className='w-full text-xs flex items-center justify-start border-b-2 border-gray-300 fila' key={i}>
                                <div className='flex flex-col items-start justify-start w-full h-full'>
                                    <div className='font-bold mb-1'>Unidad</div>
                                    {u.unidad}
                                </div>
                                <div className='flex flex-col items-start justify-start w-full overflow-ellipsis overflow-hidden'>
                                    <div className='font-bold mb-1'>Nombre</div>
                                    {u.nombre}
                                </div>
                                <div className='flex flex-col items-start justify-start w-full'>
                                    <div className='font-bold mb-1'>Piso</div>
                                    {u.piso}
                                </div>
                                <div className='flex flex-col items-start justify-start w-full'>
                                    <div className='font-bold mb-1'>Dpto</div>
                                    {u.dpto}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    function imprimir(){
        window.print();
    }

    useState(async ()=>{
        await getUsers();
    }, []);

    useState(() => {
        const newList = {};
        users.forEach(u => {
            if (!newList[`Torre ${u.torre}`]){
                newList[`Torre ${u.torre}`] = [u];
            } else {
                newList[`Torre ${u.torre}`].push(u);
            }
        })
        setLista(newList);
    }, users);

    return (
        <>
            <InternetHeader/>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='w-4/5 print:w-11/12 flex flex-col items-center justify-start'>
                    <div className='font-bold text-xl'>Lista de Propietarios</div>
                    <div className='w-full flex items-center justify-center'>
                        <A href={URLS.SERVICIOS.INTERNET + URLS.INTERNET.ADMINISTRACION} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110 noPrint'>
                            Administración
                        </A>
                        <div onClick={imprimir} className='noPrint cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110 noPrint'>
                            Imprimir
                        </div>
                    </div>
                    {
                        Object.keys(lista).map( (torre, i) => {
                            return (
                                <div key={i} className='w-full flex flex-col items-start justify-start'>
                                    <div className='w-full font-bold border-b-2 border-blue-400 mb-2'>{torre}</div>
                                    {renderList(torre)}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    )
}