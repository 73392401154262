import {A} from 'hookrouter';
import { URLS } from '../../app/constants/app';

export default function Inventory(props){
    return (
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
           <div className='font-bold text-xl'>Inventario</div>
           <p>Proximamente...</p>
           <A className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110' href={URLS.SERVICIOS.HOME}>Volver</A>
        </div>
    );
}