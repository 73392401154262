import { ToastProvider } from 'react-toast-notifications';
import {useRoutes} from 'hookrouter';
import './App.css';
import { URLS } from './app/constants/app';
import { GlobalProvider } from './app/GlobalContext';
import Select from './routes/select/Select';
import Inventory from './routes/inventory/Inventory';
import Internet from './routes/internet/Internet';
import Vales from './routes/vales/Vales';
import Entregas from './routes/vales/Entregas';
import AdminVales from './routes/vales/Admin/Admin';
import ListaNormal from './routes/vales/Admin/ListaNormal';
import NuevaPersonaInternet from './routes/internet/admin/NuevaPersona';
import NuevaPersonaVales from './routes/vales/Admin/nuevaPersona';
import NuevoVale from './routes/vales/Admin/nuevoVale';
import ModificarVale from './routes/vales/Admin/modificarVale';
import VerVale from './routes/vales/Admin/verVale';
import Login from './routes/login';
import Version from './routes/Version';
import AdminInternet from './routes/internet/admin/Admin';
import ListasInternet from './routes/internet/ListasInternet';
import ListaPropietarios from './routes/internet/admin/ListaPropietarios';

const routes = {
  [URLS.BASE.INDEX]: () => <Login />,
  [URLS.BASE.VERSION]: () => <Version/>,
  [URLS.SERVICIOS.HOME]: () => <Select />,
  // INTERNET
  [URLS.SERVICIOS.INTERNET]: () => <Internet/>,
  [URLS.SERVICIOS.INTERNET + URLS.INTERNET.ADMINISTRACION]: () => <AdminInternet/>,
  [URLS.SERVICIOS.INTERNET + URLS.INTERNET.LISTAS]: () => <ListasInternet/>,
  [URLS.SERVICIOS.INTERNET + URLS.INTERNET.NUEVA_PERSONA + '/:id']:({id}) => <NuevaPersonaInternet id={id}/>,
  [URLS.SERVICIOS.INTERNET + URLS.INTERNET.PROPIETARIOS]:() => <ListaPropietarios/>,
  // INVENTARIO
  [URLS.SERVICIOS.INVENTARIO]: () => <Inventory/>,
  // VALES
  [URLS.SERVICIOS.VALES]: () => <Vales/>,
  [URLS.SERVICIOS.VALES + URLS.VALES.ENTREGA]: () => <Entregas />,
  [URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION]: () => <AdminVales />,
  [URLS.SERVICIOS.VALES + URLS.VALES.LISTA_VALES]: () => <ListaNormal />,
  [URLS.SERVICIOS.VALES + URLS.VALES.NUEVA_PERSONA + '/:id']: ({id}) => <NuevaPersonaVales id={id} />,
  [URLS.SERVICIOS.VALES + URLS.VALES.NUEVO_VALE + '/:persona']: ({persona}) => <NuevoVale persona={persona} />,
  [URLS.SERVICIOS.VALES + URLS.VALES.EDITAR_VALE + '/:numero']: ({numero}) => <ModificarVale id={numero} />,
  [URLS.SERVICIOS.VALES + URLS.VALES.VER_VALE + '/:numero']: ({numero}) => <VerVale id={numero} />
};

function App() {
  const routeResult = useRoutes(routes);
    
  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={2000}
      placement="top-right"
    >
      <GlobalProvider>
        <main className='min-w-screen min-h-screen overflow-y-auto overflow-x-hidden bg-gray-50'>
          {routeResult}
        </main>
      </GlobalProvider>
    </ToastProvider>
  );
}

export default App;
