import { A } from 'hookrouter';
import { TEXTOS_VALES } from '../../app/constants/app';

export default function Vales(props){
    return (
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
            <div className='font-bold text-xl'>Sistema de vales</div>
            <div className='w-full flex items-center justify-center'>
                {
                    TEXTOS_VALES.INICIO.map((b, i)=>(
                        <A key={i} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110' href={b.url}>
                            <div className='text-xs'>{b.subtext}</div>
                            <div className='font-bold'>{b.text}</div>
                        </A>
                    ))
                }
            </div>
        </div>
    )
}