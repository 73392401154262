import { useEffect, useState } from "react";
import {navigate} from 'hookrouter';
import { useGlobalContext } from "../app/GlobalContext";
import { versions, URLS } from "../app/constants/app";

export default function Login(){
    const { getPersonByDocument, setUser, user } = useGlobalContext();
    const [document, setDocument] = useState('');
    const [message, setMessage] = useState('');

    async function handleLogin(){
        // al clickear buscar entre las cuentas si es admin, si lo es, setUser y entrar
        // hacer q si no hay user vuelva al /
        // cambiar esto por / y seleccion por home(en app si es diferente el path a / y no hay user navigate to /);
        // 
        const person = await getPersonByDocument(document);

        if (person){
            console.log(person);
            setUser(person);
            navigate(URLS.SERVICIOS.HOME);
        } else {
            console.log('no encontro');
            setDocument('');
            setMessage('Persona no encontrada.');// TODO: pasar a const.
        }
    }
    
    function goToVerNotes() {
        navigate(URLS.BASE.VERSION);
    }

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center">
            <div className='font-bold text-xl'>Administración Cabo corrientes</div>
                <div className="w-2/5 flex flex-col items-center justify-center">
                    <input value={document} onChange={(e)=>setDocument(e.target.value)} type="text" placeholder='documento de administrador' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='w-full flex items-center justify-between'>
                        <div onClick={handleLogin} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                            Ingresar
                        </div>
                    </div>
                </div>
                <div className='opacity-10 text-xs font-light flex items-center justify-items-center w-full'>
                    {message}
                </div>
                <div onClick={goToVerNotes} className='cursor-pointer opacity-50 text-xs font-light flex items-center justify-center w-full'>
                    ver {versions[0].name}
                </div>
        </div>
    );
}