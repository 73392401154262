import { A } from 'hookrouter';
import { useEffect, useState } from 'react';
import { URLS } from '../../../app/constants/app';
import { useGlobalContext } from '../../../app/GlobalContext';
import ValesHeader from "./components/ValesHeader";


export default function ListaNormal(props){
    const [list, setList] = useState([]);
    const [filter,setFilter] = useState('');
    const {estados, vales, getVales} = useGlobalContext();

    function parseEstado(estado){
        const e = estados.find(e => e.val === estado);
        return e.nombre || 'Sin estado';
    }
    function renderRow(data, key){
        console.log(data);
        return (
            <div key={key} className='w-full border border-blue-400 shadow mb-2 p-2'>
                        <div className='w-full flex items-center justify-between text-xs font-bold mb-2'>
                            <div className='w-full'>
                                <div>Nro</div>
                                <div className='font-light'>{data.numero}</div>
                            </div>
                            <div className='w-full'>
                                <div>Fecha</div>
                                <div className='font-light'>{data.fecha}</div>
                            </div>
                            <div className='w-full'>
                                <div>Causa</div>
                                <div className='font-light'>{data.causa}</div>
                            </div>
                            <div className='w-full'>
                                <div>Forma de Pago</div>
                                <div className='font-light'>{data.transferencia ? 'Transferencia' : 'Efectivo'}</div>
                            </div>
                            <div className='w-full'>
                                <div>Estado</div>
                                <div className='font-light'>{parseEstado(data.estado)}</div>
                            </div>
                        </div>
                        <div className='w-full flex items-center justify-between text-xs font-bold'>
                            <div className='w-full'>
                                <div>Entreado por</div>
                                <div className='font-light'>{data.source.nombre}</div>
                            </div>
                            <div className='w-full'>
                                <div>Entreado a</div>
                                <div className='font-light'>{data.target.nombre}</div>
                            </div>
                            <div className='w-full'>
                                <div>Monto Pago</div>
                                <div className='font-light'>${data.montoEntregado}</div>
                            </div>
                            <div className='w-full'>
                                <div>Monto Total</div>
                                <div className='font-light'>${data.monto}</div>
                            </div>
                            <div className='w-full flex flex-col items-center justify-start'>
                                <A href={URLS.SERVICIOS.VALES + URLS.VALES.VER_VALE + `/${data.numero}`} className='cursor-pointer text-blue-600 font-bold'>Ver Vale</A>
                                <A href={URLS.SERVICIOS.VALES + URLS.VALES.EDITAR_VALE + `/${data.numero}`} className='cursor-pointer text-blue-600 font-bold'>Editar vale</A>
                            </div>
                        </div>
                    </div>
        );
    }
    
    useEffect(() => {
        getVales();
    },[]);

    useEffect(() => {
        let newList = vales;

        if (filter !=='' && filter.length > 2){
            newList = newList.filter((v) => v.target.nombre.toLowerCase().includes(filter.toLowerCase()) || v.target.documento.includes(filter) || v.target.cuil.includes(filter));
        }
        
        newList = newList.sort((a,b) =>{
            if (a.estado > b.estado){
                return 1;
            } else {
                return -1;
            }
        });
        setList(newList);
    }, [vales, filter])
    
    
    return (
        <>
        <ValesHeader />
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='w-2/5 flex flex-col items-center justify-start'>
                    <div className='font-bold text-xl'>Lista de vales</div>
                    <input placeholder='Buscar persona...' value={filter} onChange={(e)=>setFilter(e.target.value)} type="text" className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                    <div className='w-full flex items-center justify-between'>
                        <A href={URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION} className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center cursor-pointer hover:bg-blue-500 hover:scale-110'>
                            Ver personas
                        </A>
                    </div>
                </div>
                <div className='w-2/5 flex flex-col items-center justify-center'>
                    {
                        list.map((v,i) => (
                            renderRow(v, i)
                        ))
                    }
                </div>
            </div>
        </>
    )
}