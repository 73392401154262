import { A, navigate } from 'hookrouter';
import { useState, useEffect } from 'react';
import { URLS } from "../../../app/constants/app";
import { useGlobalContext } from '../../../app/GlobalContext';
import ValesHeader from "./components/ValesHeader";

export default function ModificarVale(props){
    const {id} = props;
    const {getValeByNumber, estados, putVale} = useGlobalContext();
    const [opcion, setOpcion] = useState('elegir');
    const [vale, setVale] = useState(null);

    const [pago, setPago] = useState('');
    const [estado, setEstado] = useState(0);

    function cancelar(){
        setOpcion('elegir');
    }
    function cambiarEstado(){
        setOpcion('cambiarEstado');
    }
    function cobrar() {
        setOpcion('cobrar');
    }
    function pagar(){
        setOpcion('pagar');
    }

    function editar(){
        const d = new Date();
        const fecha = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        const newVale = vale;
        switch(opcion){
            default:
            case 'elegir':
                console.log('no hacer nada');
                break;
            case 'cobrar':
                console.log('realizar cobro $',pago);
                newVale.pagosParciales.push({fecha:fecha,monto:parseInt(-pago)});
                newVale.montoEntregado = parseInt(newVale.montoEntregado) - parseInt(pago);
                break;
            case 'cambiarEstado':
                console.log('cambiar estado a: ',estado);
                newVale.estado = parseInt(estado);
                break;
            case 'pagar':
                console.log('realizar pago $',pago);
                newVale.pagosParciales.push({fecha:fecha,monto:parseInt(pago)});
                newVale.montoEntregado = parseInt(newVale.montoEntregado) + parseInt(pago);
                break;
        }
        putVale(newVale);
        cancelar();
        setPago('');
        setEstado(0);
        navigate(URLS.SERVICIOS.VALES + URLS.VALES.LISTA_VALES);
    }

    async function elegirVale() {
        // aca se busca el vale por numero, si se encuentra se cambia el estado a 1 y se llea vale con los datos del vale.
        const data = await getValeByNumber(parseInt(id));
        if (data){
            setVale(data);
            setEstado(data.estado);
        }
    }

    useEffect(() => {
        elegirVale();
    }, []);

    return (
        <>
        <ValesHeader />
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='w-2/5 flex flex-col items-center justify-start'>
                    {
                        vale ? 
                        <>
                            <div className='w-full flex flex-col items-center justify-start'>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Nombre: </span>{vale.target.nombre}</div>
                                    <div><span className='font-bold'>Documento: </span>{vale.target.documento}</div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Causa: </span>{vale.causa}</div>
                                    <div><span className='font-bold'>Metodo: </span>{vale.transferencia ? 'Transferencia' : 'Efectivo'}</div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>cantidad pagada: </span>${vale.montoEntregado}</div>
                                    <div><span className='font-bold'>Monto final: </span>${vale.monto}</div>
                                </div>
                                <div className='w-full h-px bg-blue-600 rounded opacity-50 my-4'>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div className='font-bold'>Entregado por: </div>
                                </div>
                                <div className='w-full flex items-center justify-between'>
                                    <div><span className='font-bold'>Nombre: </span>{vale.source.nombre}</div>
                                    <div><span className='font-bold'>Documento: </span>{vale.source.documento}</div>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                    {
                        opcion === 'elegir' ?
                        <>
                            <div className='w-full flex items-center justify-center'>
                                <A href={URLS.SERVICIOS.VALES + URLS.VALES.LISTA_VALES} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>No editar</div>
                                    <div className='font-bold'>Cancelar</div>
                                </A>
                                <div onClick={pagar} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>realizar</div>
                                    <div className='font-bold'>Pago</div>
                                </div>
                                <div onClick={cobrar} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>realizar</div>
                                    <div className='font-bold'>Cobro</div>
                                </div>
                                <div onClick={cambiarEstado} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>cambiar</div>
                                    <div className='font-bold'>Estado</div>
                                </div>
                            </div>
                        </>
                    :
                        null
                    }
                    {
                        opcion === 'cambiarEstado' ?
                        <>
                            <div className='text-xs font-bold'>Cambiar estado</div>
                            <select value={estado} onChange={(e)=>setEstado(e.target.value)} className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl'>
                                {
                                    estados.map((e,i)=> <option key={i} value={e.val}>{e.nombre}</option>)
                                }
                            </select>
                            <div className='w-full flex items-center justify-center'>
                                <div onClick={cancelar} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>no editar</div>
                                    <div className='font-bold'>Volver</div>
                                </div>
                                <div onClick={editar} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>Confirmar</div>
                                    <div className='font-bold'>Cambio</div>
                                </div>
                            </div>
                        </>
                    :
                        null
                    }
                    {
                        opcion === 'pagar' || opcion === 'cobrar' ?
                        <>
                            <div className='text-xs font-bold'>{opcion === 'pagar' ? 'Pagar' : 'Cobrar'}</div>
                            <input value={pago} onChange={(e)=>setPago(e.target.value)} type="text" placeholder='Ej: 5000' className='transition duration-100 w-full mb-2 p-2 shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 focus:shadow-xl' />
                            <div className='w-full flex items-center justify-center'>
                                <div onClick={cancelar} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>no editar</div>
                                    <div className='font-bold'>Volver</div>
                                </div>
                                <div onClick={editar} className='cursor-pointer transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110'>
                                    <div className='text-xs'>Confirmar</div>
                                    <div className='font-bold'>{opcion === 'pagar' ? 'Pago' : 'Cobro'}</div>
                                </div>
                            </div>
                        </>
                    :
                        null
                    }
                </div>
            </div>
        </>
    )
}