import { A } from 'hookrouter';
import { URLS } from '../../../../app/constants/app';

export default function ValesHeader(props){
    return (
        <nav className='w-full flex items-center justify-start noPrint'>
            <div className='flex items-center justify-center'>
                <A className='transition duration-100 transform p-2 bg-blue-400 text-white shadow m-2 flex flex-col items-center justify-center hover:bg-blue-500 hover:scale-110' href={URLS.SERVICIOS.HOME}>
                    <div className='text-xs'>Volver</div>
                    <div className='font-bold'>Al inicio</div>
                </A>
            </div>
        </nav>
    )
}