// servicio activo

export const versions = [
    {
        name: "2021.1508",
        notes: [
            {
                title: 'Versión en inicio',
                description: 'Se implemento como acceso a la notas de la version el nombre de la ultima version visible en la pantalla de inicio.'
            },
            {
                title: 'Notas de la Version',
                description: 'Ahora es posible ver un registro de las actualizaciones realizadas.'
            },
            {
                title: 'Cobros en el sistema de vales.',
                description: 'Ahora es posible realizar ingresos de montos como movimiento en los vales.'
            },
            {
                title: 'Posiblemente corregido el error al iniciar que este la pagina en blanco.',
                description: 'Como no podemos replicar la situacion del problema de la pantalla en blanco, buscamos los motivos por los que puede estar ocurriendo y los solucionamos.'
            },
            {
                title: 'Metodología por fallas',
                description: 'Realizamos un sistema de fallas, donde implementamos una metodologia en conjunto con el cliente para poder solucionar de manera mas eficaz y rapida los problemas que vayan apareciendo.'
            }
        ]
    }
]

export const URLS = {
    BASE: {
        INDEX: '/',
        VERSION: '/version'
    },
    SERVICIOS: {
        HOME: '/home',
        INTERNET: '/servicio/internet',
        VALES: '/servicio/vales',
        INVENTARIO: '/servicio/inventario'
    },
    VALES: {
        ADMINISTRACION: '/administracion',
        ENTREGA: '/entrega',
        LISTA_VALES: '/lista-vales',
        NUEVA_PERSONA: '/nueva-persona',
        NUEVO_VALE: '/nuevo-vale',
        EDITAR_VALE: '/editar-vale',
        VER_VALE: '/ver-vale'
    },
    INTERNET: {
        ADMINISTRACION: '/administracion',
        LISTAS: '/listas',
        NUEVA_PERSONA:'/nueva-persona',
        PROPIETARIOS: '/propietarios'
    }
};


export const SERVICE_BUTTONS = [
    {text: 'Banda Ancha', subtext: 'Administrar', url:URLS.SERVICIOS.INTERNET},
    {text: 'Vales', subtext: 'Administrar', url:URLS.SERVICIOS.VALES},
    {text: 'Inventario', subtext: 'Administrar', url:URLS.SERVICIOS.INVENTARIO},
];

export const SERVICE_INTERNET = "Service_Internet";
export const SERVICE_VALES = "Service_Vales";
export const SERVICE_INVENTARY = "Service_Inventary";

//TEXTOS

// VALES
export const TEXTOS_VALES = {
    INICIO: [
        {text: 'Inicio', subtext: 'Volver al', url:URLS.SERVICIOS.HOME},
        {text: 'Administrador', subtext: 'Ir a', url:URLS.SERVICIOS.VALES + URLS.VALES.ADMINISTRACION},
        {text: 'Entrega', subtext: 'Ir a', url:URLS.SERVICIOS.VALES + URLS.VALES.ENTREGA}
    ]
}

// INTERNET
export const TEXTOS_INTERNET = {
    INICIO: [
        {text: 'Inicio', subtext: 'Volver al', url:URLS.SERVICIOS.HOME},
        {text: 'Administrador', subtext: 'Ir a', url:URLS.SERVICIOS.INTERNET + URLS.INTERNET.ADMINISTRACION},
        {text: 'Listas', subtext: 'Ir a', url:URLS.SERVICIOS.INTERNET + URLS.INTERNET.LISTAS}
    ]
}